<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.5"
    height="25.472"
    viewBox="0 0 19.5 25.472"
  >
    <g
      id="Mask"
      transform="translate(-8 -5)"
    >
      <path
        id="Mask-2"
        data-name="Mask"
        d="M15.545,25.472H3.954c-1.595,0-1.994-1.838-2.01-1.916L1.112,5.039H0V2.919H4.83v-.4A2.515,2.515,0,0,1,7.336,0h4.829a2.515,2.515,0,0,1,2.506,2.519v.4H19.5v2.12H18.35c0,.006,0,.049,0,.121-.092,3.283-.419,14.3-.779,18.3C17.566,23.484,17.191,25.472,15.545,25.472ZM3.226,5.039c.256,5.926.756,17.283.816,17.851a1.039,1.039,0,0,0,.143.46h11.13a1.027,1.027,0,0,0,.143-.46c.064-.6.567-13.411.738-17.851ZM7.713,1.755a.667.667,0,0,0-.725.585v.587h5.524V2.34a.669.669,0,0,0-.727-.585Zm-.034,20.38H5.765l-.005-.144L5.252,6.35H7.166l.005.143.508,15.64Zm6.055,0H11.82L12.335,6.35h1.913l-.513,15.782Zm-3.028,0H8.793V6.349h1.913V22.133Z"
        transform="translate(8 5)"
        fill="#fff"
        style="mix-blend-mode: color-burn;isolation: isolate"
      />
      <path
        id="Mask-3"
        data-name="Mask"
        d="M15.545,25.472H3.954c-1.595,0-1.994-1.838-2.01-1.916L1.112,5.039H0V2.919H4.83v-.4A2.515,2.515,0,0,1,7.336,0h4.829a2.515,2.515,0,0,1,2.506,2.519v.4H19.5v2.12H18.35c0,.006,0,.049,0,.121-.092,3.283-.419,14.3-.779,18.3C17.566,23.484,17.191,25.472,15.545,25.472ZM3.226,5.039c.256,5.926.756,17.283.816,17.851a1.039,1.039,0,0,0,.143.46h11.13a1.027,1.027,0,0,0,.143-.46c.064-.6.567-13.411.738-17.851ZM7.713,1.755a.667.667,0,0,0-.725.585v.587h5.524V2.34a.669.669,0,0,0-.727-.585Zm-.034,20.38H5.765l-.005-.144L5.252,6.35H7.166l.005.143.508,15.64Zm6.055,0H11.82L12.335,6.35h1.913l-.513,15.782Zm-3.028,0H8.793V6.349h1.913V22.133Z"
        transform="translate(8 5)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'DeleteIcon',
  }
</script>
