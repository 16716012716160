<template>
  <v-container class="mt-5">
    <v-card-title>
      الخطط
      <v-spacer />
      <v-spacer />
      <router-link
        :to="{ path: 'package'}"
        color="blue"
      >
        <v-btn
          class="mx-2"
          color="#3772FF"
        >
          إنشاء خطة
        </v-btn>
      </router-link>
    </v-card-title>
    <div :class="`plans mt-5 ${isLoading ? 'loading' : ''}`">
      <div
        v-for="plan in list"
        :key="plan.id"
        class="plan"
      >
        <div class="controllers">
          <v-btn
            icon
            text
            @click="handleDelete(plan)"
          >
            <delete-icon />
          </v-btn>
          <router-link :to="'/package/'+plan.id">
            <edit-icon />
          </router-link>
        </div>

        <p class="plan_title">
          {{ plan.name_ar }}
        </p>

        <p class="plan_description">
          {{ plan.description_ar }}
        </p>

        <p class="plan_price">
          {{ plan.price_per_month }} <span class="SAR">ريال سعودي</span>
        </p>
        <p class="plan_price_per_month_text">
          لكل شهر
        </p>

        <ul class="plan_features">
          <li
            v-for="(feature) in plan.features"
            :key="feature.id"
          >
            <v-icon
              mid
            >
              mdi-checkbox-marked-circle
            </v-icon> <span>{{ feature.feature }}</span>
          </li>
        </ul>
      </div>
    </div>
  </v-container>
</template>

<script>
  import DeleteIcon from './Icons/Delete'
  import EditIcon from './Icons/Edit'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'packages',
    components: {
      DeleteIcon,
      EditIcon,
    },
    data: () => ({
      plans: [
        {
          name_ar: 'Free',
          description: 'Lorem ipsuofficia ea laboriosam, placeat quo cum repellendus iusto. Laboriosam.',
          price_per_month: 200,
          features: [
            'up to 5 Users',
            '10 meetings',
            'up to 4 projects',
            'actions mangment',
            'file sharing integretion',
            'ticket support',
          ],
          id: 1,
        },
        {
          name_ar: 'Pro (Trial)',
          description: 'Lorem ipsuofficia ea laboriosam, placeat quo cum repellendus iusto. Laboriosam.',
          price_per_month: 100,
          features: [
            'file sharing integretion',
            'up to 5 Users',
            'up to 4 projects',
            'ticket support',
            '10 meetings',
            'actions mangment',
          ],
          id: 2,
        },
        {
          name_ar: 'Custom',
          description: 'Lorem ipsuofficia ea laboriosam, placeat quo cum repellendus iusto. Laboriosam.',
          price_per_month: 150,
          features: [
            'file sharing integretion',
            'actions mangment',
            'ticket support',
            'up to 5 Users',
            'up to 4 projects',
            '10 meetings',
          ],
          id: 3,
        },
      ],
    }),
    created () {
      this.getAllData()
    },
    computed: {
      ...mapState('package', {
        list: state => state.list,
        isLoading: state => state.isLoading,
      }),
    },
    methods: {
      ...mapActions('package', [
        'getAllData',
        'deleteItem',
      ]),
      handleDelete (plan) {
        const action = this.deleteItem.bind(this, plan.id)

        this.openDialog({
          title: 'تأكيد حذف',
          body: `هل أنت متأكد من حذف ${plan.name_ar}`,
          action,
          text: 'تأكيد',
        })
      },
    },
  }
</script>

<style lang="scss">
.plans {
    min-height: 50px;
    position: relative;
    display:flex;
    justify-content:center;
    .plan {
        position: relative;
        width:290px;
        background: white;
        border: 4px solid #3772FF;
        border-radius: 9px;
        margin:0px 10px;
        text-align: center;
        padding-top:40px;
        padding-left: 10px;
        padding-right: 10px;

        .controllers {
            display:flex;
            justify-content: space-around;
            align-items: center;
            top:-21px;
            left:50%;
            transform:translateX(-50%);
            position: absolute;
            width: 60%;
            background: #3772FF;
            border-radius: 7px;
            height: 42px;
            color: #fff;
        }

        .plan_title {
            font-size: 30px;
            margin-bottom: 10px;
        }

        .plan_description {
            margin-top:30px;
            font-size: 18px;
            font-weight: bold;
        }

        .plan_price {
            color: #3772FF;
            font-size: 30px;
            font-weight: bold;
            .SAR {
                font-size:16px;
                font-weight: 400;
            }
        }

        .plan_price , .plan_price_per_month_text {
            margin-top:0px;
            margin-bottom:0px;
            padding: 0px;;
        }

        .plan_features {
            list-style-type: none;
            margin:20px 0px;
            padding: 0px;
            li {
                margin: 4px;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                span {
                    display:inline-block;
                    margin-right:10px;
                }
                .theme--light.v-icon {
                    color:#3772FF;
                }
            }
        }
    }
}

.plans.loading {
    position: relative;
}
@keyframes example {
  from {  filter: blur(20px); top: -20px;}
  to {filter: blur(50px); top: 0px;}
}

.plans.loading::before {
  position: absolute;
  display: block;
  top: -20px;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);
  filter: blur(20px);
  animation: example 1s infinite alternate;
  background: rgb(76 76 97 / 78%);
  opacity: 0.3;
  z-index: 100;
  content: "";
}
</style>
