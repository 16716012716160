<template>
  <svg
    id="Glyphs_Quick_Action_Edit"
    data-name="Glyphs/Quick Action/Edit"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <defs>
      <clipPath id="clip-path">
        <path d="M10.383,28.5A2.879,2.879,0,0,1,7.5,25.631V11.292a2.879,2.879,0,0,1,2.884-2.868H22.346l-1.937,1.913H10.383a.959.959,0,0,0-.961.956V25.631a.959.959,0,0,0,.961.956H24.8a.959.959,0,0,0,.96-.956V15.387l1.922-1.9V25.631A2.878,2.878,0,0,1,24.8,28.5Zm5.22-8.183.737-2.744L26.825,7.123l2.044,2.023L18.384,19.595ZM27.509,6.451s.3-.3.664-.67l0,0a.962.962,0,0,1,1.35,0l.694.687a.941.941,0,0,1,0,1.337l0,0c-.37.371-.664.673-.664.673Z" />
      </clipPath>
    </defs>
    <g
      id="Group_82"
      data-name="Group 82"
      transform="translate(-7.5 -5.5)"
    >
      <g id="Mask">
        <path
          id="Mask-2"
          data-name="Mask"
          d="M2.884,23A2.879,2.879,0,0,1,0,20.131V5.792A2.879,2.879,0,0,1,2.884,2.924H14.846L12.91,4.837H2.884a.959.959,0,0,0-.961.956V20.131a.959.959,0,0,0,.961.956H17.3a.959.959,0,0,0,.96-.956V9.887l1.922-1.9V20.131A2.878,2.878,0,0,1,17.3,23ZM8.841,12.073,19.326,1.623,21.37,3.646,10.885,14.095,8.1,14.817ZM20.01.951s.3-.3.664-.671l0,0a.962.962,0,0,1,1.35,0l.694.687a.941.941,0,0,1,0,1.337l0,0c-.37.371-.664.673-.664.673Z"
          transform="translate(7.5 5.5)"
          fill="#fff"
          style="mix-blend-mode: color-burn;isolation: isolate"
        />
        <path
          id="Mask-3"
          data-name="Mask"
          d="M2.884,23A2.879,2.879,0,0,1,0,20.131V5.792A2.879,2.879,0,0,1,2.884,2.924H14.846L12.91,4.837H2.884a.959.959,0,0,0-.961.956V20.131a.959.959,0,0,0,.961.956H17.3a.959.959,0,0,0,.96-.956V9.887l1.922-1.9V20.131A2.878,2.878,0,0,1,17.3,23ZM8.841,12.073,19.326,1.623,21.37,3.646,10.885,14.095,8.1,14.817ZM20.01.951s.3-.3.664-.671l0,0a.962.962,0,0,1,1.35,0l.694.687a.941.941,0,0,1,0,1.337l0,0c-.37.371-.664.673-.664.673Z"
          transform="translate(7.5 5.5)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'EditIcon',
  }
</script>
